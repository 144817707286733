/* eslint-disable react-hooks/rules-of-hooks */
// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import {
  AsyncScheduler,
  AudioVideoFacade,
  ConsoleLogger,
  DefaultDeviceController,
  DataMessage,
  DefaultMeetingSession,
  DefaultModality,
  DeviceChangeObserver,
  LogLevel,
  MeetingSession,
  MeetingSessionConfiguration,
  DefaultActiveSpeakerPolicy,
  AllHighestVideoBandwidthPolicy,
  NScaleVideoUplinkBandwidthPolicy,
  DefaultVideoTransformDevice,
  Device,
  VideoInputDevice,
} from "amazon-chime-sdk-js";

import axios from "axios";
import throttle from "lodash/throttle";
import MessageTopic from "../enums/MessageTopic";
import useDeviceResolution from "../hooks/useDeviceResolution";
import DeviceType from "../types/DeviceType";
import FullDeviceInfoType from "../types/FullDeviceInfoType";
import MessageUpdateCallbackType from "../types/MessageUpdateCallbackType";
import RosterType from "../types/RosterType";
import { Auth } from "aws-amplify";
import DeleteMeetingAction from "../enums/DeleteMeetingStatus";

export default class ChimeSdkWrapper implements DeviceChangeObserver {
  private static DATA_MESSAGE_LIFETIME_MS = 10000;

  private static ROSTER_THROTTLE_MS = 400;

  meetingSession: MeetingSession | null = null;

  meetings: [] = [];

  audioVideo: AudioVideoFacade | null = null;

  title: string | null = null;

  name: string | null = null;

  region: string | null = null;

  currentAudioInputDevice: DeviceType | null = null;

  currentAudioOutputDevice: DeviceType | null = null;

  currentVideoInputDevice: DeviceType | null = null;

  currentVideoInputDeviceResolution: DeviceType | null = null;

  audioInputDevices: DeviceType[] = [];

  audioOutputDevices: DeviceType[] = [];

  videoInputDevices: DeviceType[] = [];

  videoInputDevicesResolution: DeviceType[] = [];

  devicesUpdatedCallbacks: ((fullDeviceInfo: FullDeviceInfoType) => void)[] =
    [];

  roster: RosterType = {};

  rosterUpdateCallbacks: ((roster: RosterType) => void)[] = [];

  configuration: MeetingSessionConfiguration | null = null;

  messageUpdateCallbacks: MessageUpdateCallbackType[] = [];

  ajaxSingleParticularId: string = "";

  // primaryFrontCameraDevice: string = '';
  // primaryBackCameraDevice: string = '';

  // currentVideoInputDeviceValue:string='';

  initializeSdkWrapper = async () => {
    this.meetingSession = null;
    this.meetings = [];
    this.audioVideo = null;
    this.title = null;
    this.name = null;
    this.region = null;
    this.currentAudioInputDevice = null;
    this.currentAudioOutputDevice = null;
    this.currentVideoInputDevice = null;
    // this.currentVideoInputDeviceValue = '';
    this.currentVideoInputDeviceResolution = null;
    this.audioInputDevices = [];
    this.audioOutputDevices = [];
    this.videoInputDevices = [];
    this.videoInputDevicesResolution = [];
    this.roster = {};
    this.rosterUpdateCallbacks = [];
    this.configuration = null;
    this.messageUpdateCallbacks = [];
    this.ajaxSingleParticularId = "";
    // this.primaryFrontCameraDevice = '';
    // this.primaryBackCameraDevice = '';
  };

  createMeetingWithAttendee = async (
    isRemoteAssist: boolean,
    isAdmin: boolean,
    Memo: string
  ): Promise<any> => {
    // for Operator
    const param = {
      Memo,
    };
    const response = await axios.post(
      this.getEndpoint("HOST") + "/create/combo",
      param,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );

    if (!response.data.Pass) {
      throw new Error("ミーティングが存在しません");
    }

    const { Attendees, Meeting } = response.data.Pass;
    if (!Attendees || !Meeting) {
      throw new Error("ミーティングが存在しません");
    }
    this.configuration = new MeetingSessionConfiguration(Meeting, Attendees[0]);
    // this.configuration.videoDownlinkBandwidthPolicy = new NoVideoDownlinkBandwidthPolicy();

    // this.configuration.videoUplinkBandwidthPolicy = new NoVideoUplinkBandwidthPolicy();
    this.configuration.videoDownlinkBandwidthPolicy =
      new AllHighestVideoBandwidthPolicy(Attendees[0].AttendeeId);

    await this.initializeMeetingSession(
      this.configuration,
      isRemoteAssist,
      isAdmin
    );
    this.title = Meeting.ExternalMeetingId;
    this.name = Attendees[0].ExternalUserId;
    this.region = Meeting.MediaRegion;
  };

  createAttendee = async (
    MeetingId: string,
    isRemoteAssist: boolean,
    isAdmin: boolean
  ): Promise<any> => {
    // for operator

    try {
      const response = await axios.post(
        this.getEndpoint("HOST") + "/create/attendee/" + MeetingId,
        null,
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        }
      );
      // @ts-ignore
      const { Attendee, Meeting } = response.data.Pass;
      this.configuration = new MeetingSessionConfiguration(Meeting, Attendee);
      // this.configuration.videoDownlinkBandwidthPolicy = new NoVideoDownlinkBandwidthPolicy();

      // this.configuration.videoUplinkBandwidthPolicy = new NoVideoUplinkBandwidthPolicy();
      this.configuration.videoDownlinkBandwidthPolicy =
        new AllHighestVideoBandwidthPolicy(Attendee.AttendeeId);

      await this.initializeMeetingSession(
        this.configuration,
        isRemoteAssist,
        true
      );
      this.title = Meeting.ExternalMeetingId;
      this.name = Attendee.ExternalUserId;
      this.region = Meeting.MediaRegion;
    } catch (e) {
      // The list looks like it's been deleted, using localStorage...
      // API: listMeetings returns deleted meetings
      // https://github.com/aws/amazon-chime-sdk-js/issues/896
      this.setParsedDeletedMeetingList(MeetingId);
      throw new Error("ミーティングが存在しません");
    }
  };

  sendData = async (PhoneNumber: string | null): Promise<any> => {
    const param = {
      MeetingId: this.meetingSession?.configuration.meetingId,
      PhoneNumber,
      IsPublish: PhoneNumber !== null,
    };
    const response = await axios.post(
      this.getEndpoint("HOST") + "/send/data",
      param,
      {
        headers: {
          Authorization: `Bearer ${(await Auth.currentSession())
            .getIdToken()
            .getJwtToken()}`,
        },
      }
    );
    return response.data.Pass.URI;
  };

  listMeeting = async (): Promise<any> => {
    try {
      const response = await axios.get(
        this.getEndpoint("HOST") + "/all/meetings",
        {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
        }
      );
      this.meetings = response!.data!.Pass!.Meetings;
    } catch (error: any) {
      this.logInfo(error);
    }
  };

  deleteMeeting = async (
    kill: boolean,
    MeetingId: string = "",
    Action: DeleteMeetingAction = DeleteMeetingAction.undef
  ): Promise<any> => {
    if (Action === DeleteMeetingAction.undef) {
      const rosterNum = Object.keys(this.roster).length;
      if (rosterNum === 2) {
        Action = DeleteMeetingAction.completed;
      } else if (rosterNum === 1) {
        Action = DeleteMeetingAction.cancelled;
      } else {
        Action = DeleteMeetingAction.error;
      }
    }
    if (kill === true) {
      try {
        const param = {
          headers: {
            Authorization: `Bearer ${(await Auth.currentSession())
              .getIdToken()
              .getJwtToken()}`,
          },
          data: {
            Action,
          },
        };
        const response = await axios.delete(
          this.getEndpoint("HOST") + "/end/" + MeetingId,
          param
        );
        if (response) {
          // console.log(response);
        }
        this.setParsedDeletedMeetingList(MeetingId);
        await this.audioVideo?.stop();
        this.initializeSdkWrapper();
      } catch (error: any) {
        this.logInfo(error);
      }
    } else {
      this.audioVideo?.stop();
      this.initializeSdkWrapper();
    }
  };

  createAttendeeMember = async (
    EncryptedMeetingId: string,
    ExternalUserId: string,
    SecretCode: string
  ): Promise<any> => {
    // for member

    const param = {
      EncryptedMeetingId,
      ExternalUserId,
      SecretCode: navigator.userAgent,
    };

    const response = await axios.post(
      this.getEndpoint("CLIENT") + "/create/member",
      param,
      {
        headers: {
          "x-api-key": process.env["REACT_APP_CLIENT_API"],
        },
      }
    );

    // const response = await axios.post('https://le8y9tk6q6.execute-api.ap-northeast-1.amazonaws.com/infra/sppus/create/attendee', param);

    //  if (response.Errors!.length > 0) {
    //  throw new Error('ミーティングが存在しません');
    //  }

    // @ts-ignore
    const { Attendee, Meeting } = response.data.Pass;
    if (!Attendee || !Meeting) {
      throw new Error("ミーティングが存在しません");
    }
    this.configuration = new MeetingSessionConfiguration(Meeting, Attendee);
    // this.configuration.videoDownlinkBandwidthPolicy = new NoVideoDownlinkBandwidthPolicy();
    this.configuration.videoUplinkBandwidthPolicy =
      new NScaleVideoUplinkBandwidthPolicy(Attendee.AttendeeId);
    this.configuration.videoUplinkBandwidthPolicy.setIdealMaxBandwidthKbps(500);
    this.configuration.videoUplinkBandwidthPolicy.setHasBandwidthPriority(true);

    await this.initializeMeetingSession(this.configuration, true, false);

    this.title = Meeting.ExternalMeetingId;
    this.name = Attendee.ExternalUserId;
    this.region = Meeting.MediaRegion;
  };

  initializeMeetingSession = async (
    configuration: MeetingSessionConfiguration,
    isRemoteAssist: boolean,
    isAdmin: boolean
  ): Promise<void> => {
    const logger = new ConsoleLogger(
      "SDK",
      process.env.NODE_ENV === "development" ? LogLevel.WARN : LogLevel.OFF
    );
    if (isAdmin === false) {
      let stream = null;
      try {
        // カメラ全般の使用許可確認
        let constraint = { video: true, audio: false };
        stream = await navigator.mediaDevices.getUserMedia(constraint);
      } catch (error: any) {
        if (error?.name === "NotAllowedError") {
          alert("カメラの使用がブラウザで許可されていません");
        } else if (error?.name === "OverconstrainedError") {
          // Pass (条件に合うカメラが存在しなかった)
        } else if (error?.name === "NotFoundError") {
          // Pass (カメラが存在しない)
        }
        console.log(error);
      } finally {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      }
    }
    // //@ts-ignore
    // if (navigator.mediaDevices === undefined) navigator.mediaDevices = {};
    // if (navigator.mediaDevices.getUserMedia === undefined) {
    //   navigator.mediaDevices.getUserMedia = function (constraints) {
    //     //@ts-ignore
    //     var getUserMedia = navigator.webkitGetUserMedia || navigator.mozGetUserMedia || navigator.msGetUserMedia || navigator.getUserMedia;
    //     if (!getUserMedia) return Promise.reject(new Error('非対応ブラウザ'));
    //     return new Promise(function (resolve, reject) {
    //       getUserMedia.call(navigator, constraints, resolve, reject);
    //     });
    //   };
    // }
    // console.log(navigator.mediaDevices);

    const deviceController = new DefaultDeviceController(logger);

    deviceController.setDeviceLabelTrigger(() => {
      return navigator.mediaDevices.getUserMedia({
        audio: false,
        video: !isAdmin,
      });
    });

    // deviceController.setDeviceLabelTrigger(
    //   async (): Promise<MediaStream> => {
    //     const stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: !isAdmin });
    //     console.log('setDeviceLabelTrigger');
    //     this.stream = stream;
    //     return stream;
    //   }
    // );
    this.meetingSession = new DefaultMeetingSession(
      configuration,
      logger,
      deviceController
    );

    this.audioVideo = this.meetingSession.audioVideo;

    this.audioInputDevices = [];

    // if (isRemoteAssist === false) {
    //   (await this.audioVideo?.listAudioInputDevices()).forEach((mediaDeviceInfo: MediaDeviceInfo) => {
    //     this.audioInputDevices.push({
    //       label: mediaDeviceInfo.label,
    //       value: mediaDeviceInfo.deviceId,
    //     });
    //   });

    //   this.audioOutputDevices = [];
    //   (await this.audioVideo?.listAudioOutputDevices()).forEach((mediaDeviceInfo: MediaDeviceInfo) => {
    //     this.audioOutputDevices.push({
    //       label: mediaDeviceInfo.label,
    //       value: mediaDeviceInfo.deviceId,
    //     });
    //   });
    // }
    this.videoInputDevices = [];

    let cameraIndex = 0;
    const tmpVideoInputDevices = await this.audioVideo?.listVideoInputDevices();
    for (let mediaDeviceInfo of tmpVideoInputDevices) {
      // let stream;
      // try {
      //   // Check if it can really be used
      //   stream = await navigator.mediaDevices.getUserMedia({
      //     audio: false,
      //     video: {
      //       deviceId: mediaDeviceInfo.deviceId,
      //     },
      //   });

      if (!mediaDeviceInfo.deviceId) continue;
      let label = "";
      if (mediaDeviceInfo.label.indexOf("front") > -1) {
        label = "フロント";
      } else if (mediaDeviceInfo.label.indexOf("back") > -1) {
        label = "リア";
      }
      this.videoInputDevices.push({
        label: `カメラ ${cameraIndex++} ${label}`,
        // label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      });

      // test
      // this.videoInputDevices.push({
      //   label: 'カメラ '+ cameraIndex++,
      //   value: mediaDeviceInfo.deviceId,
      // });

      //   stream.getTracks().forEach((track) => {
      //     track.stop();
      //   });
      // } catch (error) {
      //   console.log('do not use:' + mediaDeviceInfo.deviceId);
      //   console.log(error);
      // }
    }

    // console.log(this.videoInputDevices);

    // // Camera Candidate'
    if (this.videoInputDevices.length !== 0) {
      let stream;

      try {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: false,
          video: { facingMode: { exact: "environment" } },
        });
        console.log(stream.getVideoTracks());
        console.log(stream.getVideoTracks()[0].getSettings());
        for (let node of this.videoInputDevices) {
          if (
            node.value === stream.getVideoTracks()[0].getSettings().deviceId
          ) {
            this.currentVideoInputDevice = node;
            // @ts-ignore
            await this.audioVideo?.chooseVideoInputDevice(node.value);
          }
        }
        console.log(this.videoInputDevices);
        this.publishDevicesUpdated();
      } catch (error: any) {
        await this.chooseVideoInputDevice(
          this.videoInputDevices.length === 1
            ? this.videoInputDevices[0]
            : this.videoInputDevices[1]
        );
        console.log(error);
      } finally {
        if (stream) {
          stream.getTracks().forEach((track) => {
            track.stop();
          });
        }
      }
      // if (this.videoInputDevices.length > 1) {
      //   try {
      //     stream = await navigator.mediaDevices.getUserMedia({ audio: false, video: { width: { min: 180, max: 640 }, height: { min: 180, max: 640 }, facingMode: { exact: 'environment' } } });
      //     targetCameraDevice = stream.getVideoTracks()[0].getSettings().deviceId!;

      //   // if (this.videoInputDevices.some((e) => e.value === targetCameraDevice)) {
      //     this.primaryBackCameraDevice = targetCameraDevice;
      //   // } else {
      //     // fallback
      //     // targetCameraDevice = this.videoInputDevices[this.videoInputDevices.length - 1].value;
      //   // }

      //   } catch (error) {
      //     console.log(error);
      //   } finally {
      //     if (stream) {
      //       stream.getTracks().forEach((track) => {
      //         track.stop();
      //       });
      //     }
      //   }
      // }
    }

    this.videoInputDevicesResolution = [];
    const resolutionObj = useDeviceResolution();
    Object.keys(resolutionObj).forEach((e: any) => {
      this.videoInputDevicesResolution.push({
        label: resolutionObj[e].label,
        value: e,
      });
    });

    const resolution = useDeviceResolution()["middle"];
    this.currentVideoInputDeviceResolution = {
      label: resolution.label,
      value: "middle",
    };

    await this.audioVideo?.chooseVideoInputQuality(
      resolution.videoWidth,
      resolution.videoHeight,
      resolution.frameRate//,
      // resolution.maxBandwidthKbps
    );

    this.publishDevicesUpdated();
    this.audioVideo?.addDeviceChangeObserver(this);

    this.audioVideo?.realtimeSubscribeToAttendeeIdPresence(
      (presentAttendeeId: string, present: boolean): void => {
        if (!present) {
          delete this.roster[presentAttendeeId];
          this.publishRosterUpdate.cancel();
          this.publishRosterUpdate();
          return;
        }

        this.audioVideo?.realtimeSubscribeToVolumeIndicator(
          presentAttendeeId,
          async (
            attendeeId: string,
            volume: number | null,
            muted: boolean | null,
            signalStrength: number | null
          ) => {
            const baseAttendeeId = new DefaultModality(attendeeId).base();
            if (baseAttendeeId !== attendeeId) {
              // Don't include the content attendee in the roster.
              //
              // When you or other attendees share content (a screen capture, a video file,
              // or any other MediaStream object), the content attendee (attendee-id#content) joins the session and
              // shares content as if a regular attendee shares a video.
              //
              // For example, your attendee ID is "my-id". When you call meetingSession.audioVideo.startContentShare,
              // the content attendee "my-id#content" will join the session and share your content.
              return;
            }

            let shouldPublishImmediately = false;

            if (!this.roster[attendeeId]) {
              this.roster[attendeeId] = { name: "" };
            }
            if (volume !== null) {
              this.roster[attendeeId].volume = Math.round(volume * 100);
            }
            if (muted !== null) {
              this.roster[attendeeId].muted = muted;
            }
            if (signalStrength !== null) {
              this.roster[attendeeId].signalStrength = Math.round(
                signalStrength * 100
              );
            }

            if (
              this.ajaxSingleParticularId !== attendeeId &&
              this.title &&
              attendeeId &&
              !this.roster[attendeeId].name
            ) {
              this.ajaxSingleParticularId = attendeeId;
              const param = {
                AttendeeId: attendeeId,
              };
              const response = await axios.post(
                this.getEndpoint("CLIENT") +
                  "/single/participant/" +
                  this.configuration?.meetingId,
                param,
                {
                  headers: {
                    "x-api-key": process.env["REACT_APP_CLIENT_API"],
                  },
                }
              );

              Object.keys(response.data.Pass).forEach((key) => {
                if (this.roster[attendeeId].name !== undefined) {
                  this.roster[attendeeId] = {
                    ...this.roster[attendeeId],
                    ...response.data.Pass[key],
                    name: response.data.Pass[key]["ExternalUserId"],
                  };
                }
              });

              shouldPublishImmediately = true;
              this.ajaxSingleParticularId = "";
            }

            if (shouldPublishImmediately) {
              this.publishRosterUpdate.cancel();
            }
            this.publishRosterUpdate();
          }
        );
      }
    );

    this.audioVideo.subscribeToActiveSpeakerDetector(
      new DefaultActiveSpeakerPolicy(),
      (attendeeIds: string[]): void => {
        Object.keys(this.roster).forEach((attendeeId) => {
          this.roster[attendeeId].active = false;
        });

        attendeeIds.some((attendeeId) => {
          if (this.roster[attendeeId]) {
            this.roster[attendeeId].active = true;
            return true; // only show the most active speaker
          }
          return false;
        });
      }
    );
  };

  joinRoom = async (
    element: HTMLAudioElement | null,
    isRemoteAssist: boolean,
    isAdmin: boolean
  ): Promise<void> => {
    if (!element) {
      this.logError(new Error(`element does not exist`));
      return;
    }

    window.addEventListener(
      "unhandledrejection",
      (event: PromiseRejectionEvent) => {
        this.logError(event.reason);
      }
    );

    // if (isRemoteAssist !== true) {
    //   const audioInputs = await this.audioVideo?.listAudioInputDevices();
    //   if (audioInputs && audioInputs.length > 0 && audioInputs[0].deviceId) {
    //     this.currentAudioInputDevice = {
    //       label: audioInputs[0].label,
    //       value: audioInputs[0].deviceId,
    //     };
    //     await this.audioVideo?.chooseAudioInputDevice(audioInputs[0].deviceId);
    //   }

    //   const audioOutputs = await this.audioVideo?.listAudioOutputDevices();
    //   if (audioOutputs && audioOutputs.length > 0 && audioOutputs[0].deviceId) {
    //     this.currentAudioOutputDevice = {
    //       label: audioOutputs[0].label,
    //       value: audioOutputs[0].deviceId,
    //     };
    //     await this.audioVideo?.chooseAudioOutputDevice(audioOutputs[0].deviceId);
    //   }
    // }

    this.publishDevicesUpdated();
    if (isAdmin === true) {
      // Operator
      await this.audioVideo?.bindAudioElement(element);
    } else {
      // User
    }

    this.audioVideo?.start();
  };

  // eslint-disable-next-line
  sendMessage = (topic: string, data: any) => {
    // If you send too many messages at once, your messages may be returned to you with the throttled flag set.
    // The current throttling soft limit for Data Messages is Rate: 100, Burst: 200. If you continue to exceed the
    // throttle limit (hard limit: Rate: 500, Burst: 10000), then the server may hang up the connection.
    // https://aws.github.io/amazon-chime-sdk-js/modules/apioverview.html#9-send-and-receive-data-messages-optional

    if (
      topic === MessageTopic.Capture ||
      topic === MessageTopic.ReturnCapture
    ) {
      let chunkList: string[];
      let regexp;
      if (data.captureData.ength < 1536) {
        regexp = new RegExp(
          ".{1," + Math.round(data.captureData.length / 2) + "}",
          "g"
        );
      } else {
        regexp = new RegExp(`.{1,1536}`, "g");
      }
      chunkList = data.captureData.match(regexp);

      if (chunkList != null) {
        chunkList.forEach((chunk, index, array) => {
          let status = "process";
          if (array.length === 1) {
            status = "force";
          } else if (array.length - 1 === index) {
            status = "end";
          } else if (index === 0) {
            status = "start";
          }
          const message = {
            status: status,
            base64ImageChunk: chunk,
            index: topic === MessageTopic.ReturnCapture ? data.index : "",
          };
          this.audioVideo?.realtimeSendDataMessage(
            topic,
            message,
            ChimeSdkWrapper.DATA_MESSAGE_LIFETIME_MS
          );
          this.publishMessageUpdate(
            new DataMessage(
              Date.now(),
              topic,
              new TextEncoder().encode(JSON.stringify(message)),
              this.meetingSession?.configuration.credentials?.attendeeId || "",
              this.meetingSession?.configuration.credentials?.externalUserId ||
                ""
            )
          );
        });
      }
    } else if (topic === MessageTopic.PleaseCapture) {
      const message = {
        index: data.index,
      };
      this.audioVideo?.realtimeSendDataMessage(
        topic,
        data,
        ChimeSdkWrapper.DATA_MESSAGE_LIFETIME_MS
      );
      this.publishMessageUpdate(
        new DataMessage(
          Date.now(),
          topic,
          new TextEncoder().encode(JSON.stringify(message)),
          this.meetingSession?.configuration.credentials?.attendeeId || "",
          this.meetingSession?.configuration.credentials?.externalUserId || ""
        )
      );
    } else if (topic === MessageTopic.SendCoodinate) {
      this.audioVideo?.realtimeSendDataMessage(
        topic,
        data,
        ChimeSdkWrapper.DATA_MESSAGE_LIFETIME_MS
      );
      this.publishMessageUpdate(
        new DataMessage(
          Date.now(),
          topic,
          new TextEncoder().encode(JSON.stringify(data)),
          this.meetingSession?.configuration.credentials?.attendeeId || "",
          this.meetingSession?.configuration.credentials?.externalUserId || ""
        )
      );
    } else if (topic === MessageTopic.ToggleVideoResolution) {
      new AsyncScheduler().start(() => {
        this.audioVideo?.realtimeSendDataMessage(
          topic,
          data,
          ChimeSdkWrapper.DATA_MESSAGE_LIFETIME_MS
        );
        this.publishMessageUpdate(
          new DataMessage(
            Date.now(),
            topic,
            new TextEncoder().encode(data),
            this.meetingSession?.configuration.credentials?.attendeeId || "",
            this.meetingSession?.configuration.credentials?.externalUserId || ""
          )
        );
      });
    }
  };

  /**
   * ====================================================================
   * Device
   * ====================================================================
   */

  chooseAudioInputDevice = async (device: DeviceType) => {
    try {
      await this.audioVideo?.startAudioInput(device.value);
      this.currentAudioInputDevice = device;
    } catch (error: any) {
      this.logError(error);
    }
  };

  chooseAudioOutputDevice = async (device: DeviceType) => {
    try {
      await this.audioVideo?.startAudioInput(device.value);
      this.currentAudioOutputDevice = device;
    } catch (error: any) {
      this.logError(error);
    }
  };

  setVideoInputDeviceNull = async () => {
    await this.audioVideo?.stopVideoInput();
  };

  chooseVideoInputDevice = async (device: DeviceType) => {
    try {
      // if(this.currentVideoInputDevice?.value===device?.value)return;
      // The APIs for video processing in Amazon Chime SDK for JavaScript work in Firefox, Chrome,
      // Chromium-based browsers (including Electron) on desktop, and Android operating systems.
      // A full compatibility table is below. Currently, the APIs for video processing
      // do not support Safari/Chrome/Firefox on iOS devices due to Webkit Bug 181663.

      // https://bugs.webkit.org/show_bug.cgi?id=181663

      // const target = device !== null ? device.value : null;
      // let transformDevice = new DefaultVideoTransformDevice(logger, target, []);
      // await this.audioVideo?.chooseVideoInputDevice(transformDevice as VideoInputDevice);
      await this.audioVideo?.startVideoInput(device.value);
      this.currentVideoInputDevice = device;
      this.publishDevicesUpdated();
    } catch (error: any) {
      this.logError(error);
    }
  };

  chooseVideoInputDeviceResolution = async (device: DeviceType) => {
    try {
      const resolution = useDeviceResolution()[device.value];
      await this.audioVideo?.chooseVideoInputQuality(
        resolution.videoWidth,
        resolution.videoHeight,
        resolution.frameRate//,
        // resolution.maxBandwidthKbps
      );
      this.currentVideoInputDeviceResolution = device;
      console.log(
        resolution.videoWidth,
        resolution.videoHeight,
        resolution.frameRate,
        resolution.maxBandwidthKbps
      );
      this.publishDevicesUpdated();
    } catch (error: any) {
      this.logError(error);
    }
  };

  /**
   * ====================================================================
   * Observer methods
   * ====================================================================
   */

  audioInputsChanged(freshAudioInputDeviceList: MediaDeviceInfo[]): void {
    let hasCurrentDevice = false;
    this.audioInputDevices = [];
    freshAudioInputDeviceList.forEach((mediaDeviceInfo: MediaDeviceInfo) => {
      if (
        this.currentAudioInputDevice &&
        mediaDeviceInfo.deviceId === this.currentAudioInputDevice.value
      ) {
        hasCurrentDevice = true;
      }
      this.audioInputDevices.push({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      });
    });
    if (!hasCurrentDevice) {
      this.currentAudioInputDevice =
        this.audioInputDevices.length > 0 ? this.audioInputDevices[0] : null;
    }
    this.publishDevicesUpdated();
  }

  audioOutputsChanged(freshAudioOutputDeviceList: MediaDeviceInfo[]): void {
    let hasCurrentDevice = false;
    this.audioOutputDevices = [];
    freshAudioOutputDeviceList.forEach((mediaDeviceInfo: MediaDeviceInfo) => {
      if (
        this.currentAudioOutputDevice &&
        mediaDeviceInfo.deviceId === this.currentAudioOutputDevice.value
      ) {
        hasCurrentDevice = true;
      }
      this.audioOutputDevices.push({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      });
    });
    if (!hasCurrentDevice) {
      this.currentAudioOutputDevice =
        this.audioOutputDevices.length > 0 ? this.audioOutputDevices[0] : null;
    }
    this.publishDevicesUpdated();
  }

  videoInputsChanged(freshVideoInputDeviceList: MediaDeviceInfo[]): void {
    let hasCurrentDevice = false;
    this.videoInputDevices = [];
    freshVideoInputDeviceList.forEach((mediaDeviceInfo: MediaDeviceInfo) => {
      if (
        this.currentVideoInputDevice &&
        mediaDeviceInfo.deviceId === this.currentVideoInputDevice.value
      ) {
        hasCurrentDevice = true;
      }
      this.videoInputDevices.push({
        label: mediaDeviceInfo.label,
        value: mediaDeviceInfo.deviceId,
      });
    });
    if (!hasCurrentDevice) {
      this.currentVideoInputDevice =
        this.videoInputDevices.length > 0 ? this.videoInputDevices[0] : null;
    }
    this.publishDevicesUpdated();
  }

  getDefaultVideoTransformDevice(stream: Device): VideoInputDevice {
    const logger = new ConsoleLogger(
      "SDK",
      process.env.NODE_ENV === "development" ? LogLevel.WARN : LogLevel.OFF
    );
    return new DefaultVideoTransformDevice(logger, stream, []);
  }

  /**
   * ====================================================================
   * Subscribe and unsubscribe from SDK events
   * ====================================================================
   */

  subscribeToDevicesUpdated = (
    callback: (fullDeviceInfo: FullDeviceInfoType) => void
  ) => {
    this.devicesUpdatedCallbacks.push(callback);
  };

  unsubscribeFromDevicesUpdated = (
    callback: (fullDeviceInfo: FullDeviceInfoType) => void
  ) => {
    const index = this.devicesUpdatedCallbacks.indexOf(callback);
    if (index !== -1) {
      this.devicesUpdatedCallbacks.splice(index, 1);
    }
  };

  private publishDevicesUpdated = () => {
    this.devicesUpdatedCallbacks.forEach(
      (callback: (fullDeviceInfo: FullDeviceInfoType) => void) => {
        callback({
          currentAudioInputDevice: this.currentAudioInputDevice,
          currentAudioOutputDevice: this.currentAudioOutputDevice,
          currentVideoInputDevice: this.currentVideoInputDevice,
          currentVideoInputDeviceResolution:
            this.currentVideoInputDeviceResolution,
          audioInputDevices: this.audioInputDevices,
          audioOutputDevices: this.audioOutputDevices,
          videoInputDevices: this.videoInputDevices,
          videoInputDevicesResolution: this.videoInputDevicesResolution,
        });
      }
    );
  };

  subscribeToRosterUpdate = (callback: (roster: RosterType) => void) => {
    this.rosterUpdateCallbacks.push(callback);
  };

  unsubscribeFromRosterUpdate = (callback: (roster: RosterType) => void) => {
    const index = this.rosterUpdateCallbacks.indexOf(callback);
    if (index !== -1) {
      this.rosterUpdateCallbacks.splice(index, 1);
    }
  };

  private publishRosterUpdate = throttle(() => {
    for (let i = 0; i < this.rosterUpdateCallbacks.length; i += 1) {
      const callback = this.rosterUpdateCallbacks[i];
      callback(this.roster);
    }
  }, ChimeSdkWrapper.ROSTER_THROTTLE_MS);

  subscribeToMessageUpdate = (
    messageUpdateCallback: MessageUpdateCallbackType
  ) => {
    this.messageUpdateCallbacks.push(messageUpdateCallback);
    this.audioVideo?.realtimeSubscribeToReceiveDataMessage(
      messageUpdateCallback.topic,
      messageUpdateCallback.callback
    );
  };

  unsubscribeFromMessageUpdate = (
    messageUpdateCallback: MessageUpdateCallbackType
  ) => {
    const index = this.messageUpdateCallbacks.indexOf(messageUpdateCallback);
    if (index !== -1) {
      this.messageUpdateCallbacks.splice(index, 1);
    }
    this.audioVideo?.realtimeUnsubscribeFromReceiveDataMessage(
      messageUpdateCallback.topic
    );
  };

  private publishMessageUpdate = (message: DataMessage) => {
    for (let i = 0; i < this.messageUpdateCallbacks.length; i += 1) {
      const messageUpdateCallback = this.messageUpdateCallbacks[i];
      if (messageUpdateCallback.topic === message.topic) {
        messageUpdateCallback.callback(message);
      }
    }
  };

  /**
   * ====================================================================
   * Utilities
   * ====================================================================
   */
  private logError = (error: Error) => {
    // eslint-disable-next-line
    console.error(error);
  };

  private logInfo = (error: Error) => {
    // eslint-disable-next-line
    console.info(error);
  };

  private getEndpoint = (type: string) => {
    return (
      process.env[`REACT_APP_${type}_ENDPOINT`] +
      (type === "HOST" ? "/sppop" : "/sppus")
    );
  };

  setParsedDeletedMeetingList = (MeetingId: string): string[] => {
    let parsedDeletedMeetingList: string[] = [];
    let deletedMeetingList = localStorage.getItem("DeletedMeetingList");
    if (deletedMeetingList !== null) {
      parsedDeletedMeetingList = JSON.parse(deletedMeetingList);
    }
    const dlistLength = parsedDeletedMeetingList.length;
    if (dlistLength > 10) {
      parsedDeletedMeetingList = parsedDeletedMeetingList.slice(
        dlistLength - 10,
        dlistLength - 1
      );
    }
    parsedDeletedMeetingList.push(MeetingId);
    localStorage.setItem(
      "DeletedMeetingList",
      JSON.stringify(parsedDeletedMeetingList)
    );

    return parsedDeletedMeetingList;
  };

  isTorchEnabled = (element: HTMLVideoElement): boolean => {
    try {
      const src: MediaStream = element.srcObject as MediaStream;
      if (src === null) return false;
      const videoTrack: any = src.getVideoTracks()[0];
      const capability = videoTrack.getCapabilities();
      return capability["torch"] ? true : false;
    } catch (error: any) {
      this.logError(error);
      return false;
    }
  };
}
