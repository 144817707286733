// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React, { ReactNode } from "react";
import { Label } from "semantic-ui-react";
import classNames from "classnames/bind";
import styles from "./App.module.css";
const cx = classNames.bind(styles);
type Props = {
  children: ReactNode;
};

export default function App(props: Props) {
  const { children } = props;

  return (
    <>
      <div className={cx("env")}>
        {process.env["REACT_APP_ENV"] === "stg" && (
          <Label color="teal">Staging</Label>
        )}
        {process.env["REACT_APP_ENV"] === "dev" && (
          <Label color="orange">Develop</Label>
        )}
        {window.location.href.indexOf("8080") > -1 && (
          <Label color="red">Local</Label>
        )}
        {process.env["REACT_APP_ENV"] === "dev" && (
          <Label color="grey">{process.env["REACT_APP_VERSION"]}</Label>
        )}
      </div>
      {children}
    </>
  );
}
