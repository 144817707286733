// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import React from "react";

export const initialState: boolean = (() => {
  let hashMap: any = {};

  try {
    if (window.location.href.indexOf('/entry') !== -1) {
      return false;
    }
    if (window.location.href.indexOf("?p=") > -1) {
      window.location.hash
        .split("#/")[1]
        .split("?")
        .map((e) => {
          return e.split("&");
        })[0]
        .forEach((e) => {
          hashMap[e.split("=")[0]] = e.split("=")[1];
        });

      return false;
    } else {
      return true;
    }
  } catch (e) {
    console.log("Invalid address");
    return true;
  }
})();

export interface classModeModule {
  isAdmin: boolean;
  // getClassMode?: (status: any) => ClassMode;
}

const context = React.createContext<classModeModule>({
  isAdmin: initialState,
});

export default function getUIStateContext() {
  return context;
}
