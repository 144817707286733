// Copyright 2020 Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: Apache-2.0

import { SnackbarProvider } from "notistack";
import React, { lazy, Suspense, useRef } from "react";
import { Route, Switch } from "react-router-dom";

import App from "./components/App";
import routes from "./constants/routes.json";

export default function Routes() {
  const Home = lazy(() => import("./Routes/HomeComponent"));
  const Lobby = lazy(() => import("./Routes/LobbyComponent"));
  const Admin = lazy(() => import("./Routes/AdminComponent"));
  const Entry = lazy(() => import("./Routes/EntryComponent"));

  const notistackRef = useRef<SnackbarProvider>(null);
  const onClickDismiss = (key: string) => () => {
    notistackRef!.current!.closeSnackbar(key);
  };

  return (
    <App>
      <Suspense fallback={<p>Loading...</p>}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={1}
          action={(key: string) => (
            <button
              style={{
                backgroundColor: "rgba(0,0,0,0.3)",
                color: "white",
                cursor: "pointer",
              }}
              onClick={onClickDismiss(key)}
            >
              OK
            </button>
          )}
        >
          <Switch>
            <Route path={routes.ENTRY} component={Entry} />
            <Route path={routes.LOBBY} component={Lobby} />
            <Route path={routes.ADMIN} component={Admin} />
            <Route path={routes.HOME} component={Home} />
          </Switch>
        </SnackbarProvider>
      </Suspense>
    </App>
  );
}
