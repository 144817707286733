/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cognito_identity_pool_id": "ap-northeast-1:429dd5df-e8ad-4a30-a6d3-1a5dd4af2a57",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_aPl15oJZ3",
    "aws_user_pools_web_client_id": "5t71t9a68o54gfgd4dpiq2jkhn",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "splitmoonv3-20210412134041-hostingbucket-ext",
    "aws_content_delivery_bucket_region": "ap-northeast-1",
    "aws_content_delivery_url": "https://d1jvnps3j62exg.cloudfront.net",
    "aws_user_files_s3_bucket": "splitmoonv3c9c7ecf5ee3f4ea58ff28da3f86bc312153141-ext",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
